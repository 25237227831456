var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"QbwfuuP9RQ8XtHrSHSprj"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
   dsn: SENTRY_DSN || 'https://ae86f8dcf502478194f7bcd9bfc6a729@o4504764864987136.ingest.sentry.io/4504764865118208',
   // Adjust this value in production, or use tracesSampler for greater control
   tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.2,
   replaysSessionSampleRate: 1.0,
   replaysOnErrorSampleRate: 1.0,

   integrations: [
      // enable replays
      new Sentry.Replay(),
      // disable breadcrumbs in development
      ...(process.env.NODE_ENV === 'production' ? [] : [new Sentry.Integrations.Breadcrumbs({ console: false })]),
   ],
   // ...
   // Note: if you want to override the automatic release value, do not set a
   // `release` value here - use the environment variable `SENTRY_RELEASE`, so
   // that it will also get attached to your source maps
});
